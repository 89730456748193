<template>
  <el-row type="flex" align="middle" class="header-auth">
    <el-col :span="5">
      <router-link to="/dashboard">
        <i class="el-icon-house" /> URG-RH
      </router-link>
    </el-col>
    <el-col :span="18">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        class="user-menu"
        background-color="#409eff"
        text-color="#ffffff"
        active-text-color="#ffffff"
        @select="handleSelect"
      >
        <el-submenu index="3" popper-class="user-submenu">
          <template slot="title"><i class="el-icon-setting" />Options</template>
          <el-menu-item index="3-1">Facultés</el-menu-item>
          <el-menu-item index="3-2">Activités hors-clinique</el-menu-item>
          <el-menu-item index="3-3">Formations continues</el-menu-item>
        </el-submenu>
        <el-menu-item v-if="isAdmin" index="1"
          ><i class="el-icon-user" /><span>Utilisateurs</span></el-menu-item
        >
        <el-submenu index="2" popper-class="user-submenu">
          <template slot="title"
            ><i class="el-icon-user-solid" /><span>{{
              authUser.email
            }}</span></template
          >
          <el-menu-item index="2-1">Change le mot de passe</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-col>
    <el-col :span="1">
      <Logout />
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import Logout from "@/components/Logout";

export default {
  name: "Header",
  components: {
    Logout,
  },
  computed: {
    ...mapGetters("auth", ["authUser", "isAdmin"]),
    activeIndex() {
      const path = this.$route.path;
      if (path.startsWith("/users")) {
        return "1";
      } else if (path.startsWith("/user-updatepw")) {
        return "2-1";
      } else if (path.startsWith("/facultes")) {
        return "3-1";
      } else if (path.startsWith("/hcactivitetitres")) {
        return "3-2";
      } else if (path.startsWith("/fcontinuetitres")) {
        return "3-3";
      } else {
        return "4";
      }
    },
  },
  methods: {
    handleSelect(key) {
      if (key == "1") {
        this.$router.push({ name: "users" });
      } else if (key == "2-1") {
        this.$router.push({ name: "user_updatepw" });
      } else if (key == "3-1") {
        this.$router.push({ name: "facultes" });
      } else if (key == "3-2") {
        this.$router.push({ name: "hcactivitetitres" });
      } else if (key == "3-3") {
        this.$router.push({ name: "fcontinuetitres" });
      }
    },
  },
};
</script>
