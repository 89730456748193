<template>
  <el-button
    type="primary"
    circle
    icon="el-icon-switch-button"
    class="bt-logout"
    title="Se déconnecter"
    @click="logout"
  />
</template>

<script>
export default {
  name: "Logout",
  data() {
    return {
      toReset: [
        // [ abrév du module dans l'index du store, suffixe de l'action load]
        ["medl", "Medecin"],
        ["contl", "Contrat"],
        ["fpg", "Fpostgrad"],
        ["fc", "Fcontinue"],
        ["hcm", "Hcactivitemedecin"],
        ["tut", "Tuteur"],
        ["eva", "Evaluation"],
        ["fpr", "Fpregrad"],
        ["th", "These"],
        ["pc", "Parcoursclinique"],
        ["user", "User"],
      ],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      // vide le localStorage
      localStorage.clear();
      // efface les données de Vuex (store) et met leur loadstatus à 0
      this.toReset.forEach((e) => {
        // un item -> object
        this.$store.commit(e[0] + "/set" + e[1], {});
        this.$store.commit(e[0] + "/set" + e[1] + "LoadStatus", 0);
        // tous les item -> array d'objets
        this.$store.commit(e[0] + "/set" + e[1] + "s", []);
        this.$store.commit(e[0] + "/set" + e[1] + "sLoadStatus", 0);
      });
      // efface les prefs de l'user
      this.$store.commit("comp/setUserTablesPrefs", []);
      this.$store.commit("comp/setUserTablesPrefsLoadStatus", 0);
    },
  },
};
</script>
