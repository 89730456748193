<template>
  <el-container>
    <el-header><header-auth /></el-header>
    <router-view />
    <app-loaddata />
    <footer-auth />
  </el-container>
</template>

<script>
import AppLoaddata from "@/components/rh/layout/AppLoaddata";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Dashboard",
  components: {
    "app-loaddata": AppLoaddata,
    "header-auth": Header,
    "footer-auth": Footer,
  },
};
</script>
