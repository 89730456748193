<template>
  <el-dialog
    title="Chargement des données de l'application URG-RH"
    :visible.sync="loading"
    center
    fullscreen
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div style="text-align: center">
      <el-progress
        :percentage="loadpercent"
        color="#409EFF"
        type="circle"
        :width="200"
        :stroke-width="20"
        stroke-linecap="butt"
      ></el-progress>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: true, // true pour afficher la barre de chargement à l'ouverture de l'app
      loadpercent: 0,
      toLoads: [
        // [ abrév du module dans l'index du store, suffixe de l'action load]
        //importe les prefs de l'utilisateur
        ["comp", "Usertablesprefs"],
        // listes d'options
        ["paysl", "Pays"],
        ["contypel", "Contrattypes"],
        ["fmotifl", "Finmotifs"],
        ["permisl", "Permis"],
        ["postel", "Postes"],
        ["servl", "Services"],
        ["tauxl", "Taux"],
        ["fpgtit", "Titres"],
        ["fpgsit", "Sites"],
        ["fpgcat", "Categories"],
        ["fstatut", "Statuts"],
        ["fctit", "Titres"],
        ["fccat", "Categories"],
        ["hc", "Hcactivites"],
        ["hccat", "Categories"],
        ["fac", "Facultes"],
        ["pca", "Pcactivites"],
        // données des médecins
        ["medl", "Medecins"],
        ["contl", "Contrats"],
        ["fpg", "Fpostgrads"],
        ["fc", "Fcontinues"],
        ["hcm", "Hcactivitemedecins"],
        ["tut", "Tuteurs"],
        ["eva", "Evaluations"],
        ["fpr", "Fpregrads"],
        ["th", "Theses"],
        ["pc", "Parcourscliniques"],
      ],
    };
  },
  created() {
    // importe dans Vuex (./resources/js/store/modules/medecin.js) avec Axios les données de la bd via l'API Laravel (app/http/Controllers/API/MedecinsController.php)
    // importe dans le component racine pour éviter de chaque fois recharger les données
    // charge la première table pour lancer le watch sur le loadStatus
    const table_1er = this.toLoads[0];
    this.$store.dispatch(table_1er[0] + "/load" + table_1er[1]);
  },
  computed: {
    loadStatus() {
      // array des load status de toutes les tables à charger
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.toLoads.map(
        (t) => this.$store.state[t[0]][t[1].toLowerCase() + "LoadStatus"]
      );
    },
    // nombre de tables à charger
    nbTables() {
      return this.toLoads.length;
    },
  },
  watch: {
    // à chaque modification du array loadStatus, fait une boucle sur ce array pour charger une nouvelle table
    loadStatus: {
      deep: true, // This will let Vue know to look inside the array
      handler(loadStatus) {
        // We have to move our method to a handler field
        if (this.loading) {
          const nbTables = this.nbTables;
          const nbLoaded = loadStatus.filter((x) => x > 1).length;
          if (nbLoaded < nbTables) {
            // si aucune table en chargeement
            for (let i = 0; i < nbTables; i++) {
              if (loadStatus[i] == 0) {
                const itemToLoad = this.toLoads[i];
                //console.log(itemToLoad[0] + " -- " + itemToLoad[1]);
                this.$store.dispatch(itemToLoad[0] + "/load" + itemToLoad[1]);
                break;
              } else if (loadStatus[i] == 1) {
                // évite de recharger la table quand le loadstatus passe de 0 à 1
                break;
              } else if (loadStatus[i] == 3) {
                this.$alert(
                  "Erreur de chargement de la table <b>" +
                    this.toLoads[i][1] +
                    "</b> ! <br>Essayer de recharger la page.",
                  "Attention",
                  {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: "OK",
                    type: "warning",
                  }
                );
                break;
              }
            }
          } else {
            // après que toutes tables soient chargées
            // importe la tables des users si l'utilisateur est admin
            if (this.$store.getters["auth/isAdmin"]) {
              this.$store.dispatch("user/loadUsers");
            }
            // cache le loading screen
            this.loading = false;
          }
          this.loadpercent = Math.round((nbLoaded / nbTables) * 100);
        }
      },
    },
  },
};
</script>
