<template>
  <el-footer class="footer-auth">
    <el-row type="flex" align="middle">
      <el-col :span="24">
        <a href="mailto:info@urg-admin.ch?subject=URG RH - "
          >CHUV-URG - Contact <i class="el-icon-message" /></a
      ></el-col>
    </el-row>
  </el-footer>
</template>

<script>
export default {
  name: "Footer",
  components: {
    //
  },
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>
